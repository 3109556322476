import styled from 'styled-components';

const MobileNavigationBarContainer = styled.div`
  height: 52px;
  display: flex;
  background-color: #fff;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  padding: 0 16px;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  border-bottom: 2px solid #33333320;
  z-index: 4;
`;

export default MobileNavigationBarContainer;
