import styled from 'styled-components';

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 15px;
  padding: 8px 16px 8px 16px;
  font-weight: 900;
  max-width: 240px;

  @media (max-width: 805px) {
    padding: 8px 12px;
  }

  text-decoration: none;
  background-color: ${(props) => (props.$reverse ? '#8070fd' : '#FFFFFF')};
  color: ${(props) => (props.$reverse ? '#FFFFFF' : '#8070fd')};
  border: ${(props) =>
    props.$border ? (props.$reverse ? '1px solid #FFFFFF' : '1px solid #8070fd') : '0px solid #FFFFFF'};

  &:hover {
    background-color: ${(props) => (props.$reverse ? '#FFFFFF' : '#8070fd')};
    color: ${(props) => (props.$reverse ? '#8070fd' : '#FFFFFF')};
    border: ${(props) =>
      props.$border ? (props.$reverse ? '1px solid #8070fd' : '1px solid #FFFFFF') : '0px solid #8070fd'};
  }

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

export default Button;
