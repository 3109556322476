import _i18n from 'i18next';
import layoutBGTranslations from '../../locales/bg/layout.json';
import browserLang from 'browser-lang';
import {getLanguageFromIP, getLanguageFromPath} from '.';

const languageFromPath = getLanguageFromPath();
const languageFromBrowser = browserLang();
const languageFromStorage = typeof localStorage !== 'undefined' && localStorage.getItem('gatsby-i18next-language');

_i18n.init({
  resources: {bg: {translation: layoutBGTranslations}},
  fallbackLng: false,
  lng: languageFromPath || languageFromStorage || languageFromBrowser,
  interpolation: {
    escapeValue: false,
  },
});

async function setLanguageFromIP() {
  const languageFromIP = await getLanguageFromIP();

  if (!languageFromPath && languageFromBrowser !== languageFromIP) {
    _i18n.changeLanguage(languageFromIP);
  }
}

setLanguageFromIP(); // TODO: Make a custom hook so components will rerender on language change

export default _i18n;
