exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-add-place-js": () => import("./../../../src/pages/add-place.js" /* webpackChunkName: "component---src-pages-add-place-js" */),
  "component---src-pages-bg-privacy-policy-index-js": () => import("./../../../src/pages/bg/privacy_policy/index.js" /* webpackChunkName: "component---src-pages-bg-privacy-policy-index-js" */),
  "component---src-pages-bg-terms-index-js": () => import("./../../../src/pages/bg/terms/index.js" /* webpackChunkName: "component---src-pages-bg-terms-index-js" */),
  "component---src-pages-business-contact-us-js": () => import("./../../../src/pages/business/contact-us.js" /* webpackChunkName: "component---src-pages-business-contact-us-js" */),
  "component---src-pages-business-demo-js": () => import("./../../../src/pages/business/demo.js" /* webpackChunkName: "component---src-pages-business-demo-js" */),
  "component---src-pages-business-index-js": () => import("./../../../src/pages/business/index.js" /* webpackChunkName: "component---src-pages-business-index-js" */),
  "component---src-pages-business-sign-up-js": () => import("./../../../src/pages/business/sign-up.js" /* webpackChunkName: "component---src-pages-business-sign-up-js" */),
  "component---src-pages-en-privacy-policy-index-js": () => import("./../../../src/pages/en/privacy_policy/index.js" /* webpackChunkName: "component---src-pages-en-privacy-policy-index-js" */),
  "component---src-pages-en-terms-index-js": () => import("./../../../src/pages/en/terms/index.js" /* webpackChunkName: "component---src-pages-en-terms-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lead-js": () => import("./../../../src/pages/lead.js" /* webpackChunkName: "component---src-pages-lead-js" */)
}

