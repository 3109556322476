import styled from 'styled-components';
import HamburgerMenuIconFilled from '../../assets/images/hamburger-menu-filled.png';
import HamburgerMenuIcon from '../../assets/images/hamburger-menu.png';

const HamburgerButton = styled.button`
  position: fixed;
  right: 15px;
  width: 20px;
  height: 15px;
  background: transparent url(${(props) => (props.inverse ? HamburgerMenuIcon : HamburgerMenuIconFilled)}) no-repeat;
  background-size: 20px 15px;
  border-width: 0;
`;

export default HamburgerButton;
