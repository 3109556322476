// eslint-disable-next-line no-unused-vars
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import './Container.css';

export const WindowContext = React.createContext();

export default function Container({children}) {
  // This component was used to hold Context Provider logic that is no longer needed. Maybe we should delete
  // it in the future

  return (
    <div style={{margin: '0 auto'}}>
      {children}
      {/* <Footer /> */}
    </div>
  );
}

Container.propTypes = {
  children: PropTypes.object,
};
