import {get} from 'lodash';
/* eslint-disable no-useless-escape */
function getLanguageFromPath() {
  // prettier-ignore
  const regex = '\/en\/|\/bg\/';

  if (typeof window !== 'undefined') {
    const rawMatch = get(window.location.pathname.match(regex), '[0]');
    if (rawMatch) {
      // prettier-ignore
      const formattedLanguage = rawMatch.replace(/\//g, '');
      return formattedLanguage;
    }
  }

  return null;
}

export default getLanguageFromPath;
