// eslint-disable-next-line no-unused-vars
import React, {useEffect, useState} from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import Cookies from 'js-cookie';
import styled from 'styled-components';
import {Link} from 'gatsby';
import {Button} from '../styled';
import i18n from '../../helpers/i18n';

const Container = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 50%;
  background-color: #8070fd;
  padding: 32px;
  z-index: 100;
  border-radius: 8px;
  min-width: 520px;
  /* height: 100px; */

  @media (max-width: 560px) {
    min-width: auto;
    width: 100%;
    padding: 20px;
  }
`;

const Header = styled.div`
  font-size: 1.8rem;
  font-weight: bold;
  color: white;
`;

const Text = styled.div`
  font-size: 1.6rem;
  color: white;
  margin-top: 20px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;

  @media (max-width: 560px) {
    flex-direction: column;
  }
`;

const CTAButton = styled(Button)`
  min-width: 200px;
  margin-bottom: 10px;

  @media (max-width: 560px) {
    flex: 1;
  }
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 10px;
  right: 10px;

  :hover {
    opacity: 0.4;
    color: white;
    background-color: #8070fd;
  }
`;

const PrivacyPolicyLink = styled(Link)`
  color: white;
  font-size: 1.6rem;
  text-decoration: underline;

  @media (max-width: 560px) {
    flex: 1;
    text-align: left;
    margin-bottom: 12px;
  }
`;

function CookieBanner() {
  // Default is set to true because of SSR
  const [allowed, setIsAllowed] = useState(null);

  useEffect(() => {
    const areCookiesAllowed = Cookies.get('COOKIES_ALLOWED');
    if (allowed !== areCookiesAllowed) {
      setIsAllowed(areCookiesAllowed);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAllow = () => {
    Cookies.set('COOKIES_ALLOWED', true, {expires: 365});

    setIsAllowed(true);
  };

  if (allowed || allowed === null) {
    return null;
  }

  return (
    <Container>
      <CloseButton onClick={handleAllow} $reverse>
        <CancelIcon sx={{fontSize: 32}} />
      </CloseButton>
      <Header>{i18n.t('Cookie Notice')}</Header>
      <Text>
        {i18n.t(
          // eslint-disable-next-line max-len
          'We and selected partners, use cookies or similar technologies as specified in the cookie policy. You can consent to the use of such technologies by clicking "Allow" or closing this notice.',
        )}
      </Text>
      <ButtonsContainer>
        <PrivacyPolicyLink to="/en/privacy_policy">{i18n.t('Privacy Policy')}</PrivacyPolicyLink>
        <CTAButton onClick={handleAllow} $border>
          {i18n.t('Allow')}
        </CTAButton>
      </ButtonsContainer>
    </Container>
  );
}

export default CookieBanner;
