import fetchWithTimeout from './fetchWithTimeout';

async function getCountryCodeFromIP() {
  try {
    const response = await fetchWithTimeout(`${process.env.GATSBY_API_URL}/utils/geoip`, {timeout: 2500});
    let {countryCode} = await response.json();
    return countryCode;
  } catch (e) {
    console.log(e);
  }

  return null;
}

export default getCountryCodeFromIP;
