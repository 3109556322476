import styled from 'styled-components';
import {Link} from 'gatsby-plugin-react-i18next';

export const DrawerContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: left;
  width: 100vw;
  padding: 26px 16px;
  text-transform: uppercase;
`;

export const DrawerItem = styled(Link).attrs(() => ({className: 'mobile-menu-item'}))`
  padding: 8px;
  margin: 12px 0px;
  font-weight: 900;
  font-size: 0.95em;
  border-radius: ${(props) => (props.$border ? '10px' : '0px')};
  text-align: left;
  background-color: ${(props) => (props.$reverse ? '#8070fd' : '#FFFFFF')};
  color: ${(props) => (props.$reverse ? '#FFFFFF' : '#8070fd')};
  border: ${(props) => (props.$border ? '1px solid #8070fd' : '')};
  border-bottom: ${(props) => (props.$borderBottom ? '1px solid lightgray' : '')};
`;

export const ActionDrawerItems = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;

export const DrawerHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: calc(100vw - 32px);
  margin-bottom: 40px;
`;
