import styled from 'styled-components';

const NavigationBarContainer = styled.nav`
  height: 72px;
  display: flex;
  background-color: #fff;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  align-items: center;
  text-transform: uppercase;
  border-bottom: 2px solid #33333320;
  margin: 0 auto;
  padding: 0 24px;
  z-index: 4;
  align-self: center;
`;

export default NavigationBarContainer;
