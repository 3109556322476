import {withPrefix} from 'gatsby';

const removePathPrefix = (pathname) => {
  const pathPrefix = withPrefix('/');
  if (pathname.startsWith(pathPrefix)) {
    return pathname.replace(pathPrefix, '/');
  }

  return pathname;
};

export default removePathPrefix;
