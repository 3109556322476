import styled from 'styled-components';
import CloseIcon from '../../assets/images/close-filled.png';

const CloseButton = styled.button`
  width: 20px;
  height: 20px;
  background: transparent url(${CloseIcon}) no-repeat;
  background-size: 20px 20px;
  border-width: 0;
`;

export default CloseButton;
