import {isUserAgentAndroid} from '.';

export default function getDownloadLink() {
  const isAndroid = isUserAgentAndroid();

  const downloadLink = isAndroid
    ? 'https://play.google.com/store/apps/details?id=com.cindyapp.client'
    : 'https://apps.apple.com/bg/app/cindy/id1480948058?ls=1';

  return downloadLink;
}
