// eslint-disable-next-line no-unused-vars
import React from 'react';
import {Helmet} from 'react-helmet';
import {withPrefix} from 'gatsby';
import {getLanguageFromIP, getLanguageFromPath, removePathPrefix} from './src/helpers';
import '@fontsource/comfortaa'; // Defaults to weight 400 with all styles included.
import '@fontsource/poppins';
// Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from './src/components/Container/Container';
import './src/styles.css';
import CookieBanner from './src/components/CookieBanner/CookieBanner';

import AppleTouchFavIcon from './src/assets/images/favicon-sets/apple-touch-icon.png';
import FavIcon16 from './src/assets/images/favicon-sets/favicon-16x16.png';
import FavIcon34 from './src/assets/images/favicon-sets/favicon-32x32.png';
// import SiteWebManifest from './src/assets/images/favicon-sets/site.webmanifest';
import SafariPinnedTab from './src/assets/images/favicon-sets/safari-pinned-tab.svg';
import getCountryCodeFromIP from './src/helpers/getCountryCodeFromIP';

const onRouteUpdate = ({location, prevLocation}) => {
  if (typeof window !== 'undefined') {
    // if (window._dcq) {
    //   window._dcq.push([
    //     'track',
    //     `Visited page "${location.pathname}"`,
    //     {
    //       source: 'site',
    //       url: location.href,
    //       success(response) {
    //         localStorage.setItem('refId', response.visitor_uuid);
    //       },
    //     },
    //   ]);
    // }
  }
};

const onInitialClientRender = () => {
  async function setLanguage() {
    const languageFromIP = await getLanguageFromIP();
    if (!languageFromIP) {
      return;
    }

    const currentLanguage = localStorage.getItem('gatsby-i18next-language');
    // Check if language is different and path doesn't contain a language
    const languageFromPath = getLanguageFromPath();
    if (currentLanguage !== languageFromIP && !languageFromPath) {
      localStorage.setItem('gatsby-i18next-language', languageFromIP);

      const queryParams = window.search || '';
      const newUrl = withPrefix(
        `/${languageFromIP}${removePathPrefix(window.location.pathname)}${queryParams}${window.location.hash}`,
      );

      window.___replace(newUrl);
    }
  }
  setLanguage();

  async function setCountryCodeIfNeeded() {
    if (!localStorage.getItem('country')) {
      const countryCode = await getCountryCodeFromIP();
      localStorage.setItem('country', countryCode);
    }
  }

  setCountryCodeIfNeeded();
};

// Wraps every page in a component
const wrapPageElement = ({element, props}) => {
  return (
    <Container {...props}>
      <Helmet>
        <meta name="facebook-domain-verification" content="tod7cr30opvpdzpeesm0j2q1b2o46l" />
        <script>{`!function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '735347177097252');
          fbq('track', 'PageView');
        `}</script>
        <noscript>
          {`
          <img height="1" width="1" style="display:none"
          src="https://www.facebook.com/tr?id=735347177097252&ev=PageView&noscript=1"/>
        `}
        </noscript>
        <script>
          {`var chatbox = document.getElementById('fb-customer-chat');
          chatbox.setAttribute("page_id", "114781603778332");
          chatbox.setAttribute("attribution", "biz_inbox");`}
        </script>
        <script>
          {`window.fbAsyncInit = function() {
          FB.init({
            xfbml            : true,
            version          : 'v12.0'
          });
        };
        (function(d, s, id) {
          var js, fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) return;
          js = d.createElement(s); js.id = id;
          js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
          fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
        `}
        </script>
        <link rel="apple-touch-icon" sizes="180x180" href={AppleTouchFavIcon} />
        <link rel="icon" type="image/png" sizes="32x32" href={FavIcon34} />
        <link rel="icon" type="image/png" sizes="16x16" href={FavIcon16} />
        <link rel="manifest" href="./src/assets/images/favicon-sets/site.webmanifest" />
        <link rel="mask-icon" href={SafariPinnedTab} color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>
      {element}
      <CookieBanner />
      <div id="fb-root" />
      <div id="fb-customer-chat" class="fb-customerchat" />
    </Container>
  );
};

export {wrapPageElement, onInitialClientRender, onRouteUpdate};
