import styled from 'styled-components';
import {Button} from '../styled';

export const ActionMenuItems = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-left: 24px;
`;

export const MenuItem = styled(Button).attrs(() => ({className: 'menu-item'}))`
  display: flex;
  align-items: center;
  border-radius: 8px;
  font-weight: 900;
  font-size: 1.4rem;
  white-space: nowrap;

  @media (max-width: 1092px) {
    padding: 6px 12px;
  }
`;

export const MenuItemsContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`;

export const MainMenuItems = styled.div`
  margin: 0px 24px;
  display: flex;
  flex: 1;
  gap: 16px;
  justify-content: center;
`;
